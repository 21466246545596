import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IEventsSummary} from '../../shared/model/interface/events-summary';
import {IExcelColumnList} from '../../shared/model/interface/excel-column.interface';
import {IBase64File} from '../../shared/model/interface/base64-file.model.interface';
import {environment} from '../../../environments/environment';
import {HttpParamsFactory} from "../../shared/factory/http-params.factory";
import {IEventsSearch} from "../../shared/model/interface/events-search";

@Injectable({
  providedIn: 'root'
})
export class EventsSummaryService {

  constructor(private http: HttpClient) { }

  private URL = environment.backendUrl;

  export(search: IEventsSearch, payload: IEventsSummary) {
    const params = HttpParamsFactory.notNullsFromObject(search)
    return this.http.post<IBase64File>(this.URL + '/events/export', payload, {params})
  }

  getColumns() {
    return this.http.get<IExcelColumnList>(this.URL + '/events/export/columns')
  }
}
