import {AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ILegalCaseSearch} from '../../model/interface/legal-case-search.model.interface';

@Component({
  selector: 'app-legal-case-searcher-dialog',
  templateUrl: './legal-case-searcher-dialog.component.html',
  styleUrls: ['./legal-case-searcher-dialog.component.css']
})
export class LegalCaseSearcherDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('view') searcherView;

  @HostListener('keydown.enter', ['$event']) onKeydownEnter() {
    if (this.formValid) {
      this.search(this.form.value)
    }
  }

  get form() {
    return this.searcherView?.searchFormGroup
  }

  get formValid() {
    return this.valid;
  }

  valid = false;
  data;

  constructor(private matDialogRef: MatDialogRef<LegalCaseSearcherDialogComponent>, private cdr: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this._updateValidity();
    this.form.valueChanges.subscribe(() => {
      this._updateValidity();
    })
  }

  search(form) {
    this.matDialogRef.close(this._createSearchParams(form));
  }

  private _updateValidity() {
    this.valid = this.form.valid;
    this.cdr.detectChanges();
  }

  private _createSearchParams(form) {
    return {
      onlyOnGoing: form.onlyOnGoing === true ? true : null,
      briefcaseNumber: form.briefcaseNumber,
      oldBriefcaseNumber: form.oldBriefcaseNumber,
      stateId: form.stateId,
      coreCreatedAfter: form.coreCreatedAt?.from?.format('YYYY-MM-DD'),
      coreCreatedBefore: form.coreCreatedAt?.to?.format('YYYY-MM-DD'),
      createdAfter: form.createdAt?.from?.format('YYYY-MM-DD'),
      createdBefore: form.createdAt?.to?.format('YYYY-MM-DD'),
      endAfter: form.endAt?.from?.format('YYYY-MM-DD'),
      endBefore: form.endAt?.to?.format('YYYY-MM-DD'),
      vindicatorId: form.vindicatorId,
      fieldAttorneyId: form.fieldAttorneyId,
      contractNumber: form.contractNumber,
      instructingId: form.instructingId,
      instructingGroupId: form.instructingGroupId,
      debtorCoreId: form.debtorCoreId,
      legalCaseDescription: form.legalCaseDescription,
      debtorNIP: form.debtorNIP,
      supervisorId: form.supervisorId,
      contractItem: form.contractItem,
      contractItemTypeId: form.contractItemTypeId,
      contractTypeId: form.contractTypeId,
      transitionalParkingLotAfter: form.transitionalParkingLotAt?.from?.format('YYYY-MM-DD'),
      transitionalParkingLotBefore: form.transitionalParkingLotAt?.to?.format('YYYY-MM-DD'),
      targetParkingLotAfter: form.targetParkingLotAt?.from?.format('YYYY-MM-DD'),
      targetParkingLotBefore: form.targetParkingLotAt?.to?.format('YYYY-MM-DD'),
      debtorNumber: form.debtorNumber,
      postingNumber: form.postingNumber,
      phone: form.phone,
      email: form.email,
      postalCode: form.postalCode,
      initialBalanceGte: form.initialBalance?.from,
      initialBalanceLte: form.initialBalance?.to,
      balanceGte: form.balance?.from,
      balanceLte: form.balance?.to,
      lastPaymentAfter: form.lastPaymentAt?.from?.format('YYYY-MM-DD'),
      lastPaymentBefore: form.lastPaymentAt?.to?.format('YYYY-MM-DD'),
      lastPaymentAmountGte: form.lastPaymentAmount?.from,
      lastPaymentAmountLte: form.lastPaymentAmount?.to,
      externalContractId: form.externalContractId
    } as ILegalCaseSearch
  }
}
