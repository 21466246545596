import {
  IMoney,
  IMoneyList,
  INettoMoney,
  ISimpleMoney,
  ISimpleMoneyList,
  ISimpleTaxedMoney,
  ITaxedMoney,
  ITaxedMoneyList
} from '../model/interface/money.model.interface';
import formatter from 'format-number';
import Big from 'big.js';
import {Money, MoneyList} from '../model/money.model';
import {ObjectListHelper} from './object-list.helper';
import {MoneyCalculator} from '../utils/money-calculator';
import {TaxedMoney, TaxedMoneyList} from "../model/taxed-money.model";
import {INettoMoneyCalculator} from "../utils/taxed-money-calculator";

export class MoneyHelper {

  static sumToText(instance: Money[]) {
    const moneyCalculator = new MoneyCalculator(instance);
    return moneyCalculator.sum()
        .data
        .map(money => money.toText())
        .join('\n');
  }

  static sumTaxedToText(instance: INettoMoney[]) {
    const moneyCalculator = new INettoMoneyCalculator(instance);
    return moneyCalculator.sum()
        .data
        .map(money => money.toText())
        .join('\n');
  }

  static sumToHTML(instance: Money[]) {
    const moneyCalculator = new MoneyCalculator(instance);
    return moneyCalculator.sum()
        .data
        .map(money => money.toHTML())
        .join('<br>');
  }

  static sumTaxedToHTML(instance: INettoMoney[]): string {
    const moneyCalculator = new INettoMoneyCalculator(instance);
    return moneyCalculator.sum()
        .data
        .map(money => money.toHTML())
        .join('<br>');
  }

  static tryToParseStringToMoneyValue(value: any) {
    if (typeof value === 'string') {
      value = value.replace(/,/g, '.')
          .replace(/ /g, '');
    }
    try {
      return new Big(value);
    } catch (e) {
      return null;
    }
  }

  static numberValueToText(value: number) {
    return formatter({decimal: ',', integerSeparator: ' ', padRight: 2})(value);
  }

  static moneyValueToText(value: Big) {
    return this.numberValueToText(value.toNumber());
  }

  static listToText(money: TaxedMoneyList | MoneyList) {
    return money.data
        .map(instance => instance.toText())
        .join('\n');
  }

  static toIMoneyList(data: MoneyList): IMoneyList {
    const iMoneyArray = data.data
        .map(money => MoneyHelper.toIMoney(money));
    return ObjectListHelper.fromArray(iMoneyArray);
  }

  static toITaxedMoneyList(data: TaxedMoneyList): ITaxedMoneyList {
    const iMoneyArray = data.data
        .map(money => MoneyHelper.toITaxedMoney(money));
    return ObjectListHelper.fromArray(iMoneyArray);
  }


  static toIMoney(data: Money): IMoney {
    return {
      value: data.value.toNumber(),
      currency: data.currency
    };
  }

  static toINettoMoney(money: TaxedMoney): INettoMoney {
    return {
      value: money ? money.netto.toNumber() : null,
      currency: money ? money.currency : null,
      vat: money ? money.vatRate : null,
    };
  }

  static toITaxedMoney(data: TaxedMoney): ITaxedMoney {
    return {
      netto: data.netto.toNumber(),
      brutto: data.brutto.toNumber(),
      currency: data.currency,
      vat: data.vatRate,
    } as ITaxedMoney;
  }

  static taxedToINettoMoney(data: ITaxedMoney): INettoMoney {
    return {
      value: data ? data.netto : null,
      currency: data ? data.currency : null,
      vat: data ? data.vat : null,
    };
  }

  static toSimpleMoneyList(data: MoneyList): ISimpleMoneyList {
    const simpleMoneyArray = data.data
        .map(money => this.toSimpleMoney(money));
    return ObjectListHelper.fromArray(simpleMoneyArray);
  }

  static toSimpleMoney(instance: Money): ISimpleMoney {
    return {
      value: instance ? instance.value.toNumber() : null,
      currencyId: instance ? instance.currency.id : null
    };
  }

  static toSimpleTaxedMoney(instance: TaxedMoney): ISimpleTaxedMoney {
    return {
      value: instance ? instance.netto.toNumber() : null,
      currencyId: instance ? instance.currency.id : null,
      vatRateId: instance ? instance.vatRate.vatRateId : null,
    };
  }

  static nettoToSimpleTaxedMoney(instance: INettoMoney): ISimpleTaxedMoney {
    return {
      value: instance ? instance.value : null,
      currencyId: instance ? instance.currency.id : null,
      vatRateId: instance ? instance.vat.vatRateId : null,
    };
  }
}
