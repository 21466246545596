<h1 class="primary-color"> Zestawienie zdarzeń</h1>

<mat-stepper linear #stepper>
  <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
    <ng-template matStepLabel>Wybór zdarzeń</ng-template>
    <form [formGroup]="firstFormGroup">
      <app-search-by-date-from-to label="Data wystąpienia zdarzenia"
                                  formControlName="date"></app-search-by-date-from-to>
      <app-simple-multiple-select-value-accessor label="Typ zdarzenia"
                                                 [data]="availableEventType"
                                                 [defaultSelectedValues]=""
                                                 formControlName="types">
      </app-simple-multiple-select-value-accessor>
        <app-search-by-date-from-to label="Data utworzenia sprawy w systemie"
                                formControlName="legalCaseDate"></app-search-by-date-from-to>
        <app-search-customer label="Zlecający" instructing="true" formControlName="lessorId">

        </app-search-customer>

      <div>
        <button mat-stroked-button matStepperNext (click)="_createCheckboxForms()">Dalej</button>
      </div>
    </form>
  </mat-step>
  <mat-step [editable]="isEditable">
    <ng-template matStepLabel>Wybór kolumn</ng-template>
    <div class="select-buttons">
      <button mat-raised-button
              color="primary"
              (click)="selectAll()">
        Zaznacz wszystko
      </button>
      <button mat-raised-button
              color="accent"
              (click)="unselectAll()">
        Odznacz wszystko
      </button>
    </div>
    <form [formGroup]="form">
      <div formArrayName="columns" class="column-forms">
        <ng-container #container></ng-container>
      </div>
    </form>
    <button mat-stroked-button class="export-button" (click)="export()">
      Pobierz
    </button>
  </mat-step>
</mat-stepper>
